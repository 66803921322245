
import React, { useState } from 'react'
import Layout from '../../components/layout'
import styled, { keyframes } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, Paper, RadioGroup, Radio, FormControlLabel, FormLabel, Button } from '@material-ui/core'
import { Colors } from '../../constants'
import { EmojiObjects, Code, Build, PhoneAndroid } from '@material-ui/icons'
import Fade from 'react-reveal/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core'
import Particles from '../../views/services/_particles'
import EventNoteIcon from '@material-ui/icons/EventNote';

import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { openPopupWidget } from "react-calendly"
import ReCAPTCHA from "react-google-recaptcha";

const SentImg = require('../../dist/images/sent.svg')
const ContactImg = require('../../dist/images/contact.svg')

export default () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [preferredContactMethod, setPreferredContactMethod] = useState('Email')
    const [reason, setReason] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')    
    const [emailAddress, setEmailAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [additionalInfo, setAdditionalInfo] = useState('')
    const [company, setCompany] = useState('')
    const [formHasErrors, setFormHasErrors] = useState(false)

    const [hasSentMessage, setHasSentMessage] = useState(false)

    const URL = null;
    try { URL = `${window.location.protocol}//${window.location.host}` } catch {}

    async function postContactInquiry(contactInfo) {
        await fetch(`${window.location.protocol}//${window.location.host}/api/contact`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(contactInfo)
        }).catch((error) => {
            console.log(error)
        })
    }

    const recaptchaRef = React.createRef();

    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        
        if(token) {
            if(firstName.length >= 1 && lastName.length >= 1 && reason) {
                if(preferredContactMethod === 'Phone' && phoneNumber.length >= 1 || preferredContactMethod === 'Email' && emailAddress.length >= 1) {
                    postContactInquiry({ subject: reason, firstName, lastName, email: emailAddress, phone: phoneNumber, additionalInfo, company, preferredContactMethod, meta: 'auxidus-com' });
                    setHasSentMessage(true);
                    console.log(token);
                } else {
                    setFormHasErrors(true);
                }                                                
            } else {
                setFormHasErrors(true);
            }
        }     
      }

    return (
        <Layout padTop={true} title={'Contact Us'}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6Letus0ZAAAAANhZ0m6LFVMheFTX_0RRQV9ZlnQO"
                style={{ display: 'none' }}
            />

            <Banner>
                <Particles />
                <BannerTextContainer>
                    <Container style={{display: 'flex', flexDirection: 'column'}}>
                        <BannerTextMain variant={'button'}>Contact Us</BannerTextMain>
                    </Container>                        
                </BannerTextContainer>
            </Banner>
            <div style={{background: '#F9F9F9', height: '20vmax'}} />
            <Separater />
            <MainBackgroundContainer>
                <FormWrapper>
                    <FormContainer>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                {
                                    !hasSentMessage ? <Form>
                                    <form noValidate autoComplete="off">

                                        {/* Reason for contact */}
                                        <FormFieldLabel variant={'h6'}>
                                            Tell us about <span style={{ color: Colors.auxidusBlue }}>you</span>
                                        </FormFieldLabel>
                                        <div style={{marginTop: '15px'}}>
                                            <FormControl fullWidth required>
                                                <InputLabel id="demo-controlled-open-select-label">How can we help?</InputLabel>
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    open={isDropdownOpen}
                                                    onClose={() => setIsDropdownOpen(false)}
                                                    onOpen={() => setIsDropdownOpen(true)}
                                                    value={reason}
                                                    onChange={(e) => setReason(e.target.value)}
                                                    error={ !reason && formHasErrors }
                                                >
                                                    <MenuItem value="" disabled style={{ color: 'gray' }}>
                                                        Please Select an Option
                                                    </MenuItem>
                                                    <MenuItem value={'New Web Application'}>Develop a New Web Application</MenuItem>
                                                    <MenuItem value={'Reinventing Existing Product'}>Reinvent an Existing Product</MenuItem>
                                                    <MenuItem value={'Website Development'}>Professional Website Development</MenuItem>
                                                    <MenuItem value={'IT Consultation'}>IT Infrastructure Consultation</MenuItem>
                                                    <MenuItem value={'General Inquiry'}>None of These Apply</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <Grid spacing={2} container style={{marginTop: '0px'}}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth required>
                                                    <StyledTextField 
                                                        label="First Name" 
                                                        value={firstName} 
                                                        onChange={(e) => setFirstName(e.target.value)} 
                                                        required 
                                                        error={ firstName.length < 1 && formHasErrors }
                                                        helperText={ formHasErrors && firstName.length < 1 ? 'Field is required.' : null }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth required>
                                                    <StyledTextField 
                                                        label="Last Name" 
                                                        value={lastName} 
                                                        onChange={(e) => setLastName(e.target.value)} 
                                                        required 
                                                        error={ lastName.length < 1 && formHasErrors }
                                                        helperText={ formHasErrors && lastName.length < 1 ? 'Field is required.' : null }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <FormControl fullWidth>
                                            <StyledTextField label="Company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                        </FormControl>
                                        <Typography style={{marginTop: '25px'}}>Contact Method</Typography>
                                        <Grid spacing={1} container>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <FormControl component="fieldset" fullWidth>
                                                    <RadioGroup value={preferredContactMethod} onChange={(e) => setPreferredContactMethod(e.target.value)} required>
                                                        <FormControlLabel 
                                                            value="Email" 
                                                            style={{paddingTop: '11px'}} 
                                                            control={<Radio style = {{ color: Colors.auxidusBlue }} />} 
                                                            label="Email" 
                                                        />
                                                        <FormControlLabel 
                                                            value="Phone" 
                                                            style={{paddingTop: '6px'}} 
                                                            control={<Radio style = {{ color: Colors.auxidusBlue }} />} 
                                                            label="Phone" 
                                                            error={ preferredContactMethod === 'Phone' && phoneNumber.length < 10 && formHasErrors }
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8} md={9} lg={10}>
                                                <FormControl fullWidth>
                                                    <StyledTextField 
                                                        label="Email Address" 
                                                        value={emailAddress} 
                                                        onChange={(e) => setEmailAddress(e.target.value)} 
                                                        required={preferredContactMethod === 'Email'}
                                                        error={ preferredContactMethod === 'Email' && emailAddress.length < 1 && formHasErrors }
                                                        helperText={ preferredContactMethod === 'Email' && emailAddress.length < 5 && formHasErrors ? 'Field is required.' : null }
                                                     />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <StyledTextField 
                                                        label="Phone Number" 
                                                        value={phoneNumber} 
                                                        onChange={(e) => setPhoneNumber(e.target.value)} 
                                                        required={preferredContactMethod === 'Phone'} 
                                                        error={ preferredContactMethod === 'Phone' && phoneNumber.length < 10 && formHasErrors }
                                                        helperText={ preferredContactMethod === 'Phone' && phoneNumber.length < 10 && formHasErrors ? 'Field is required.' : null }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <FormControl fullWidth required>
                                            <StyledTextField
                                                id="outlined-multiline-static"
                                                label="Additional Information"
                                                multiline                                                
                                                rows={4}
                                                variant="outlined"
                                                style={{marginTop: '15px', outlineWidth: '2px', '&:focused': {border: '2px solid orange !important'}}}
                                                value={additionalInfo}
                                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                            />
                                        </FormControl>

                                        <div style={{marginTop: '25px', textAlign: 'right'}}>
                                    <Button variant="contained" color="secondary" style={{background: Colors.auxidusBlue}} 
                                            onClick={() => openPopupWidget({
                                                url: 'https://calendly.com/info-auxidus/free-project-consultation',
                                                prefill: {
                                                    firstName: `${firstName}`,
                                                    lastName: `${lastName}`,
                                                    email: `${emailAddress}`,
                                                    customAnswers: {
                                                    a1: `${company}`,
                                                    a2: `${additionalInfo}`
                                                }}                                                
                                                })}>
                                            <EventNoteIcon />
                                        </Button>
                                        <Button 
                                            onClick={() => onSubmitWithReCAPTCHA()}
                                            variant="contained" color="primary" style={{background: Colors.auxidusBlue, color: 'white', marginLeft: '15px'}}
                                        >
                                                Request Information
                                            </Button>
                                        </div>
                                    </form>

                                    
                                </Form>
                                
                                :   <Form style={{height: '622px'}}>
                                        <Typography variant={'h3'} style={{color: Colors.auxidusBlue, fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Thank you
                                        </Typography>
                                        <InfoSeparator type={'confirm'} />
                                        <Typography variant={'h5'} style={{ color: 'gray', marginTop: '50px' }}>
                                            Your message has been sent. One of our consultants will reach out to you shortly.
                                        </Typography>

                                        <SentImageWrapper>
                                            <SentImage state={hasSentMessage} />
                                        </SentImageWrapper>


                                    </Form>
                                }
                                
                            </Grid>                            
                            <Grid item xs={12} md={5} style={{padding: '10px'}}>
                                <FormInfo>
                                    <InfoTextAlt>
                                        Think we can help with your next project?
                                    </InfoTextAlt>
                                    <InfoTextMain>
                                        Let's Connect
                                    </InfoTextMain>
                                </FormInfo>
                                <InfoSeparator />

                                <Grid container spacing={2}>
                                
                                <ContactInfoContainer>

                                    <ContactImageContainer item xs={5} md={12} />

                                    <Grid item xs={12} sm={7} md={12}>
                                        <ContactInfoWrapper>
                                            <ContactRow>
                                                <ContactIcon>
                                                    <PhoneAndroidIcon className={'child'} />
                                                </ContactIcon>
                                                <div style={{display: 'flex', flexDirection: 'column'}} onClick={() => window.location.href = "tel:+19093533953"}>
                                                    <ContactInfo>Santa Ana Office</ContactInfo>
                                                    <ContactText>1-909-353-3953</ContactText>
                                                </div>
                                            </ContactRow>
                                            <ContactRow>
                                                <ContactIcon>
                                                    <MailOutlineIcon className={'child'} />
                                                </ContactIcon>
                                                <div style={{display: 'flex', flexDirection: 'column'}} onClick={() => window.location.href = "mailto:hello@auxidus.com"}>
                                                    <ContactInfo>Sales &amp; Operations</ContactInfo>
                                                    <ContactText>hello@auxidus.com</ContactText>
                                                </div>
                                            </ContactRow>
                                            <ContactRow type={'location'}>
                                                <ContactIcon>
                                                    <LocationOnIcon className={'child'} />
                                                </ContactIcon>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <ContactInfo>#340-2048, Irvine, CA 92604</ContactInfo>
                                                    <ContactText>15333 Culver Drive</ContactText>
                                                </div>
                                            </ContactRow>
                                            {/* <OfficeHoursInfo>
                                                <InfoTextAlt>9:00 AM - 5:00 PM (M-F)</InfoTextAlt>
                                            </OfficeHoursInfo> */}
                                        </ContactInfoWrapper>
                                    </Grid>
                                </ContactInfoContainer>
                            </Grid>
                            </Grid>
                        </Grid>

                    </FormContainer>               
                </FormWrapper>
            </MainBackgroundContainer>
        </Layout>
    )
}

const ContactImageContainer = styled(Grid)`
    background: url(${ContactImg}) no-repeat center /cover;
    text-align: center;
    width: 100%;
    height: 200px;

    @media only screen and (max-width: 959px) {
        float: right;
        padding-top: 25px;
    }
    @media only screen and (max-width: 750px) {
        display: none;
    }
`;

const sentImageIn = keyframes`
  0% {
    height: 0px;
  }
  30%, 100% {
    width: 150px;
  }
  100% {
    height: 250px;
  }
`

const SentImageWrapper = styled.div`
    height: 250px;
    width: 100%;
    display: block;
    margin-top: 125px;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

    @media only screen and (max-width: 425px) {
        margin-top: 50px;
    }
    @media only screen and (max-width: 340px) {
        margin-top: 25px;
    }
    @media only screen and (max-width: 300px) {
        margin-top: 5px;
    }
`;
const SentImage = styled.div`
    background: url(${SentImg}) no-repeat center center;
    background-size: contain;
    width: 100%;
    margin: auto;

    animation: ${sentImageIn} 2s ease forwards;
    -webkit-animation: ${sentImageIn} 2s ease forwards;
    -moz-animation: ${sentImageIn} 2s ease forwards;
    -ms-animation: ${sentImageIn} 2s ease forwards;
    -o-animation: ${sentImageIn} 2s ease forwards;
`;
const OfficeHoursInfo = styled.div`
    margin-top: 15px;
`;
const ContactIcon = styled.div`
    background: ${Colors.auxidusBlue};
    color: #f3f3f3;
    padding: 5px;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    height: 35px;
    width: 35px;

    .child {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0.8;
    }
`;
const ContactInfo = styled(Typography)`
    font-size: calc(15px + 3 * ((35vw - 600px) / 480));
    line-height: calc(15px + 3 * ((35vw - 600px) / 480));
    padding-bottom: 3px;
    opacity: 0.8;
    color: grey;
    padding-left: 25px;
`;
const ContactText = styled(Typography)`
    line-height: 30px;
    margin-left: 25px;
    font-size: 30px;
    color: ${Colors.auxidusBlue};
    width: 100%;
`;
const ContactRow = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    height: 100%;
    cursor: ${props => props.type === 'location' ? 'default' : 'pointer'};
    margin-bottom: 25px;
`;
const InfoSeparator = styled.div`
    margin: ${props => props.type === 'confirm' ? '25px 0px' : '25px 15px'};
    display: block;
    border: none;
    height: 3px;
    width: ${props => props.type === 'confirm' ? '5%' : '7.5%'};
    background: ${Colors.auxidusBlue};
`;
const Separater = styled.div`
    background-color: #fefefe;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='12' viewBox='0 0 40 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z' fill='%23666666' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: 10px;
    width: 100%;
`;
const Banner = styled.div`
    width: 100%;
    height: 20vmax;
    position: relative;
    background-color: #F9F9F9;
    border-bottom: 2px solid ${Colors.auxidusBlue};
`;
const BannerTextContainer = styled.div`
    position: absolute;
    bottom: 25px; left: 0; right: 0;
    width: 100%;
`;
const BannerTextMain = styled(Typography)`
    font-size: calc(22px + 4 * ((100vw - 185px) / 380));
    font-weight: bold;
    max-width: 500px;
    color: ${Colors.backgroundBlueLighter};
    line-height: calc(22px + 6 * ((100vw - 185px) / 380));
`;
const InfoTextMain = styled(Typography)`
    margin-top: 12px;
    font-size: calc(22px + 4 * ((100vw - 185px) / 580));
    font-weight: bold;
    color: ${Colors.auxidusBlue};
    line-height: calc(6px + 6 * ((100vw - 185px) / 580));
    text-transform: uppercase;
`;
const InfoTextAlt = styled(Typography)`
    font-size: calc(9px + 4 * ((100vw - 185px) / 580));
    line-height: calc(9px + 6 * ((100vw - 185px) / 580));
    color: grey;
    opacity: 0.7;
    @media only screen and (max-width: 959px) {
        font-size: calc(5px + .25vw + .75vh + 1vmin);
        line-height: calc(5px + .25vw + .75vh + 1vmin);
    }    
`;
const StyledTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: props => !props.error ? Colors.auxidusBlue : 'red',
      },
      '&:hover .MuiInput-underline:before': {
        borderBottomColor: Colors.auxidusBlue,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: props => !props.error ? Colors.auxidusBlue : 'red',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderWidth: 1
        },
        '&:hover fieldset': {
          borderColor: Colors.auxidusBlue,
        },
        '&.Mui-focused fieldset': {
          borderColor: Colors.auxidusBlue,
        },
      },
    },
  })(TextField);
const FormFieldLabel = styled(Typography)`
    font-size: calc(16px + 6 * ((100vw - 185px) / 540));
`;
const Form = styled(Paper)`
    padding: 25px;
    position: relative;
`;
const FormInfo = styled.div`
    padding: 10px;
`;
const FormWrapper = styled.div`
    position: absolute;
    top: -12vmax;
    left: 0; right: 0;
`;

const FormContainer = styled(Container)`
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
    margin: 0 auto;
`;
const ContactInfoContainer = styled(Container)`
`;
const ContactInfoWrapper = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
    flex-direction: column;
    padding-top: 85px;
    @media only screen and (max-width: 959px) {
        padding-top: 25px;
    }
`;
const MainBackgroundContainer = styled.div`
    min-height: 525px;
    @media only screen and (max-width: 959px) {
        min-height: 925px;
    }
    height: 100%;
    width: 100%;
    position: relative;
`;